.service-cards {
    margin: 0 auto;

    display: grid;
    gap: 0;
    grid-auto-rows: minmax(33vh, .5fr);
    grid-template-columns: repeat(auto-fill, minmax(30vw, 400px));
    grid-auto-flow: row;
}

#services-container {
    background: var(--bg-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}