:root {
  --shadow-color-1: #000;
  --shadow-color-2: #1200ff;
  --shadow-color-3: #f00;
  --shadow-color-4: #ff0;
  --default-shadow: #000 -1px -1px 0, #000 -2px -2px 0, #000 -3px -3px 0;
  --hover-shadow: #000 -2px -2px 0, #000 -4px -4px 0, #000 -6px -6px 0;
  --hover-shadow-2: var(--shadow-color-1) -3px -3px 0, var(--shadow-color-2) -6px -6px 0, var(--shadow-color-3) -9px -9px 0, var(--shadow-color-4) -12px -12px 0;
  --hover-shadow-3: var(--shadow-color-1) -1px -1px 0, var(--shadow-color-2) -2px -2px 0, var(--shadow-color-3) -3px -3px 0, var(--shadow-color-4) -4px -4px 0;
  --bg-color: #2effff;
}

body {
  background: #000;
  font-size: 14px;
  font-family: 'Fjalla One', Helvetica, Verdana, Arial, sans-serif;
}

.brand {
  font-size: 4rem;
  color: #fff;
  text-shadow: #000 -2px -2px 0;
  margin: 0;
  padding: 1rem;
  padding-bottom: 0;
}

@keyframes bg-scroll {
  from {
    transform: translateY(-15em);
  }
  to {
    transform: translateY(-1.765em);
  }
}

#page-container {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
}

.about-container,
.services-container {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;

  flex-grow: 1;
  flex-basis: auto;
  position: relative;
  overflow: hidden;
}
