
.work-player {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #2effff;
    visibility: hidden;
    transform: translateY(-101%);
    transition: all .25s ease-out;
    overflow: hidden;
}

.work-player-content {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;

    width: auto;
    height: 100%;
    position: relative;
    z-index: 3;
    /*padding-right: 8em;*/
}

.work-player-info {
    flex-basis: 25%;
    box-sizing: border-box;
    padding: 2rem;
    background: #2effff;
    opacity: 0;
    transition: all .25s ease-out;
    border-left: 12px #000 double;
}

h1#work-player-info-title {
    color: #fff;
    text-shadow: #000 3px -2px 0;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

h2#work-player-info-client {
    font-weight: bold;
    margin: 0;
    padding: 0;
}

div#work-player-info-description {
    padding: 1rem 0;
    font-size: 1.1rem;
}


.work-container.player-loaded .work-player-info {
    opacity: 1;
}

.work-vimeo-player {
    width: auto;
    flex-grow: 1;
    flex-basis: 50%;
}

.work-vimeo-frame {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 3;
}

#work-player-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    line-height: .5em;
    z-index: 4;
    font-size: 8rem;
    cursor: pointer;
    color: #fff;
    text-shadow: #000 3px -2px 0;
    font-weight: bold;
    text-decoration: none;
}

#work-player-close > div {
    transition: transform .3s ease-out;
}

#work-player-close:hover > div,
#work-player-close:active > div {
    transform: rotateZ(90deg);
    text-shadow: #000 3px -2px 0, #f0ff00 6px -4px 0;
}
