
#about-container {
    background: var(--bg-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#about-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

#about-image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#about-image > img {
    object-fit: contain;
    max-width: 100%;
}



/* original about css stuff */
.about-background {
    position: absolute;
    top: 0;
    right: 0;
    /* don't cover full page */
    left: 40%;
    bottom: 35%;
    image-rendering: pixelated;
    background: url('/public/img/about/dotbg-web.gif') repeat;
}

.about-info {
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 8%;
    position: relative;
    font-size: 2.5rem;
    line-height: 2em;
    margin: auto;
}

.about-info > span {
    /*text-decoration: underline;*/
}

.about-decoration-bubble {
    flex-basis: 30%;
    position: relative;
}

.about-decoration-cat {
    flex-basis: 20%;
    position: relative;
}

.about-decoration-inner {
    display: flex;
    flex-direction: column;
}
.about-decoration-inner > div {
    flex-grow: 1;
}

/* 70x35, ratio is 50% */
.about-bubble-constrain {
    padding-top: 50%;
    position: relative;
    margin: 0 auto;
}

.about-bubble-image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.about-bubble-image > img {
    min-width: 100%;
    max-width: 100%;
    image-rendering: pixelated;
}

.about-bubble-text {
    position: absolute;
    z-index: 2;
    top: 8%;
    left: 11%;
    bottom: 40%;
    right: 11%;
    /*padding: 8% 11% 23% 11%;*/
    box-sizing: border-box;
    font-family: 'Press Start 2P', sans-serif;
    font-size: 1rem;
    line-height: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.about-bubble-text > div {
}




/* 64x148, ratio is 231.25% */
.about-cat-constrain {
    padding-top: 231.25%;
    position: relative;
    margin: 0 auto;
}

.about-cat-image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.about-cat-image > img {
    min-height: 100%;
    max-height: 100%;
    image-rendering: pixelated;
    transform: rotateZ(80deg);
}
