#top-menu {
  align-self: start;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  z-index: 100;
}

#top-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 3em;
  text-shadow: var(--default-shadow);
  transition: text-shadow ease-out .1s;
}

#top-menu div {
  color: #fff;
  font-size: 4em;
  text-shadow: var(--default-shadow);
}


#top-menu a:hover, #top-menu a:active {
  text-shadow: var(--hover-shadow-2);
}

#top-menu a.gvsfk-link {
  color: #ff0000;
}