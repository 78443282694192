#nav-with-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: var(--bg-color);
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}

#nav-brand a {
  color: #fff;
  text-decoration: none;
  font-size: 4rem;
  text-shadow: var(--default-shadow);
  transition: text-shadow ease-out .3s;
  font-weight: bold;
}

#nav-brand a:hover {
  text-shadow: var(--hover-shadow-2);
}

@media screen and (max-width: 768px) {
  #nav-with-brand {
    flex-direction: column-reverse;
    gap: 20px;
  }

  #top-menu {
    gap: 10px;
    width: 100%;
    justify-content: center;
  }

  #top-menu a, #top-menu div {
    font-size: 1.5rem;
  }
}