
.work-container {
    flex-grow: 1;
    flex-basis: auto;
    position: relative;
    background: #000;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.work-items {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}

.work-item {
    display: -webkit-flex;
    display: flex;
    flex-basis: 16.66%;
    align-items: center;
    position: relative;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    z-index: 0;
    box-sizing: border-box;
    color: #000;
}

.work-label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    /* hide for now */
    display: none;
}

.work-label-hover {
    /*position: absolute;*/
    /*top: 2em;*/
    /*left: 0;*/
    /*right: 0;*/
    color: #000;
    font-size: 50%;
}

.work-label-hover > .work-label-title {
    font-size: 1.75rem;
    font-weight: bold;
}

.work-label-hover > .work-label-client {
    font-size: 1.5rem;
}


.work-play {
    display: none;
}

.work-item > img {
    min-width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    /*width: 100%;*/
}

.work-item-hover {
    display: -webkit-flex;
    display: flex;
    align-content: center;
    flex-direction: column;

    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #f0ff00;
    transform: translateY(101%);
    transition: transform .1s ease-out;
    visibility: hidden;
}

.work-item:hover .work-item-hover,
.work-item:active .work-item-hover {
    transform: translateY(0);
    visibility: visible;
}

.work-item-hover-text {
    margin: auto 0;
    font-size: 1.5rem;
}

.work-item-watch {
    font-size: 1.5rem;
    transition: .5s ease-out letter-spacing;
    background: #000;
    color: #f0ff00;
    font-weight: bold;
    margin-top: 1rem;
    padding: .125rem 0;
}

.work-item:hover .work-item-watch {
    letter-spacing: .3rem;
}

.work-label-title, .work-label-client {
    /*transform: translateY(-100%) scale(200%);*/
    /*transition: .5s ease-out all;*/
    /*letter-spacing: .3rem;*/
}

.work-item:hover :is(.work-label-title, .work-label-client) {
    /*transform: scale(100%) translateY(-10px);*/
    /*letter-spacing: normal;*/
}

.work-item-hover-text:hover,
.work-item-hover-text:active {
    /*text-shadow: #2effff 0px 7px 0, #fff 0px 14px 0;*/
}

.work-container.player-open {
    overflow: hidden;
}

.work-container.player-open .work-player {
    visibility: visible;
    transform: translateY(0%);
    z-index: 200;
}

.work-container.player-closed .work-player {
    visibility: visible;
}

.work-player-loading {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 8rem;
    color: #fff;
    text-shadow: #000 3px -2px 0;
    font-weight: bold;
    animation-name: bg-scroll;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transition: all .25s ease-out;
    font-family: 'Fjalla One', Helvetica, Verdana, Arial, sans-serif;
}

.work-container.player-loaded .work-player-loading {
    color: #000;
    display: none;
}
.work-container.player-loaded .work-player {
    background: #000;
}