:root {
  --rb1: #f00;
  --rb2: #ff8700;
  --rb3: #ffd300;
  --rb4: #deff0a;
  --rb5: #a1ff0a;
  --rb6: #0aff99;
  --rb7: #0aefff;
  --rb8: #147df5;
  --rb9: #580aff;
  --rb10: #be0aff;
}


.contact-left {
  flex-basis: 50%;
  text-align: center;
  flex-grow: 1;
}


.contact-size {
  width: 100%;
  margin: 0 auto;
}

/* contact is 120x120, aspect ratio is 100%  */
/* only 75% because width */
.contact-constrain {
  padding-top: 75%;
  width: 75%;
  position: relative;
  margin: 0 auto;
}

.contact-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.contact-inner > img {
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
}

.contact-right {
  flex-basis: 50%;
  text-align: center;
  display: flex;
  /*align-items: flex-end;*/
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 1;
}

.notepad-size {
  width: 90%;
  margin: 0 auto;
}

/* notepad is 240x164, aspect ratio is  68.33%  */
.notepad-constrain {
  padding-top: 68.33%;
  position: relative;
  width: 100%;
}

.notepad-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.notepad-inner > img {
  min-width: 100%;
  max-width: 100%;
  image-rendering: pixelated;
}

#contact-container {
  background: var(--bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#contact-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

#contact-image {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact-image > img {
  object-fit: contain;
  max-width: 100%;
}

#contact-details {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.contact-detail-1 {
  /*background: var(--shadow-color-4);*/
  /*padding: 0 .75rem;*/
  display: flex;
  flex-direction: row;
  gap: 2rem;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.contact-detail-1 > a {
  text-decoration: none;
  text-shadow:
      #000 -2px -2px 0;
  /*text-shadow: rgba(0,0,0,.5) 2px 2px 4px, rgba(255,255,255,1) -4px -4px 8px;*/
}

.contact-detail-1:hover {
  background: none;
}

.contact-detail-1:hover a {
  color: var(--shadow-color-4);
}

.contact-detail-1:nth-child(1) a {
  color: var(--rb1);
}
.contact-detail-1:nth-child(2) a {
  color: var(--rb2);
}
.contact-detail-1:nth-child(3) a {
  color: var(--rb3);
}
.contact-detail-1:nth-child(4) a {
  color: var(--rb4);
}
.contact-detail-1:nth-child(5) a {
  color: var(--rb5);
}
.contact-detail-1:nth-child(6) a {
  color: var(--rb6);
}

.contact-detail-1:nth-child(7) a {
  color: var(--rb7);
}

.contact-detail-1:nth-child(8) a {
  color: var(--rb8);
}

.contact-detail-1:nth-child(9) a {
  color: var(--rb9);
}

.contact-detail-1:nth-child(10) a {
  color: var(--rb10);
}



@media screen and (max-width: 768px) {
  #contact-content {
    flex-direction: column;
    align-items: center;
  }

  #contact-image {
    width: 100%;
  }

  .contact-detail-1 {
    flex-direction: column;
    gap: 0;
  }
}