#home-container {
    width: 100vw;
    height: 100vh;
    /*max-width: 1200px;*/
    background: var(--bg-color);
    position: relative;
    overflow: hidden;
}

#brand-home-flex {
    display: flex;
    position: absolute;
    top: calc(25vh - 5vw);
    width: 100%;
    align-items: center;
    justify-content: center;
}

#brand-home-container {
    position: relative;
}

.brand-home {
    color: #fff;
    position: relative;
    z-index: 50;
}

.brand-home, .brand-shadow-1, .brand-shadow-2, .brand-shadow-3, .brand-shadow-4 {
    font-size: 6rem;
    text-align: center;
    line-height: 0;
}

.brand-shadow-1, .brand-shadow-2, .brand-shadow-3, .brand-shadow-4 {
    position: absolute;
    top: 0;
    left: 0;
}

.brand-shadow-1 {
    color: var(--shadow-color-1);
    z-index: 49;
}

.brand-shadow-2 {
    color: var(--shadow-color-2);
    z-index: 48;
}

.brand-shadow-3 {
    color: var(--shadow-color-3);
    z-index: 47;
}

.brand-shadow-4 {
    color: var(--shadow-color-4);
    z-index: 46;
}

.flex-grow {
    flex-grow: 1;
}

#home-footer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    pointer-events: none;
}

.brand-animation .brand-shadow-1 {
    transform: translate(-3px, -3px);
}

.brand-animation .brand-shadow-2 {
    transform: translate(-6px, -6px);
}

.brand-animation .brand-shadow-3 {
    transform: translate(-9px, -9px);
}

.brand-animation .brand-shadow-4 {
    transform: translate(-12px, -12px);
}

@media screen and (max-width: 768px) {
    .brand-home, .brand-shadow-1, .brand-shadow-2, .brand-shadow-3, .brand-shadow-4 {
        font-size: 4rem;
    }

    #brand-home-flex {
        top: calc(15vh - 5vw);
    }
}