/* ###########Small Only########### */
@media screen and (max-width: 768px){
    .work-item {
        flex-basis: 100%;
    }

    .work-label {
        position: absolute;
        bottom: auto;
        right: auto;
        top: 50%;
        margin-top: -15px;
        display: block;
        text-align: left;
        background: rgba(0,0,0,.5);
        padding: .5em;
        font-size: 1.25em;
        color: #fff;
        margin-left: 1em;
    }

    .work-label > div {
        font-size: .75rem;
        color: rgba(255,255,255,.7);
    }

    .work-play {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 2rem;
        line-height: 4rem;
        margin-left: -15px;
        margin-top: -15px;
        color: #fff;
        background: rgba(0,0,0,.5);
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        text-align: center;
    }

    .menu {
        font-size: 1.15rem;
    }
    .glasses {
        max-width: 95%;
    }

    .menu > ul > li {
        flex-basis: 19%;
    }

    .about-info {
    }
}/* ###########END of Small Only########### */
/* ###########Small & up####################################### */

/* ###########END of Small & up################################# */
/* ###########Medium & up########### */
@media screen and (min-width: 768px){
    .work-item {
        flex-basis: 33.33%;
    }
    .menu {
        font-size: 2rem;
    }

}/* ###########END of Medium & up########### */
/* ###########Large & up########### */
@media screen and (min-width: 992px){
    .work-item {
        flex-basis: 25%;
    }
    .menu {
        font-size: 3.5rem;
    }
    .glasses {
        max-width: 70%;
    }

    .about-info {
        padding: 2% 3%;
        font-size: 2rem;
    }
}/* ###########End of Large & up########### */
/* ######### X-Large & up############# */
@media screen and (min-width: 1100px){
    .work-item {
        flex-basis: 20%;
    }
    .menu {
        font-size: 4rem;
    }

}/* ######### End of X-Large & up############# */
/* ##########Super Large & Up ############ */
@media screen and (min-width: 1200px){
    .work-item {
        /*flex-basis: 16.66%;*/
    }
    .glasses {
        max-width: 60%;
    }

}/* ########## End of Super Large & Up ############ */


/* ########## Super Large & Down ############ */
@media (max-width: 1280px){

}/* ########## End of Super Large & Down ############ */

/* ########## Large & Down ############ */
@media (max-width: 1100px){


}/* ########## End of Large & Down ############ */

/* ########## Medium & Down ############ */
@media (max-width: 992px){

    .work-player-content {
        flex-direction: column;
    }
    .work-player-info {

    }


    .about-container {
        flex-direction: column;
        overflow-y: auto;
    }

    .about-decoration-cat {
        order: 1;
    }

    .about-cat-image {
        text-align: center;
    }

    .about-cat-image > img {
        transform: none;
        min-height: initial;
        max-height: initial;
        min-width: 70%;
        max-width: 70%;
    }

    .about-cat-constrain {
        padding: 0;
    }

}/* ########## End of Medium & Down ############ */


/* ########## Small & Down ################################## */
@media (max-width: 768px){

    .contact-container {
        flex-direction: column;
    }



}/* ########## End of Small & Down ####################### */
